import { useEffect } from 'react'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { useClientOnly } from '~/components/ClientOnly'

export type MenuOption = {
  label: string
  onActivate?: () => void
  toggleable?: boolean
}

type AdminContextMenuStore = {
  isOpen: boolean
  position: { x: number; y: number }
  options: Record<string, MenuOption[]>
  toggleStates: { [label: string]: boolean }

  actions: {
    closeMenu: () => void
    openMenu: () => void
    setPosition: (position: { x: number; y: number }) => void
    toggleOption: (label: string) => void
  }
}

export const useAdminContextMenuStore = create<AdminContextMenuStore>()(
  persist(
    (set) => ({
      isOpen: false,
      position: { x: 0, y: 0 },
      options: {},
      toggleStates: {},

      actions: {
        closeMenu: () => set({ isOpen: false }),
        openMenu: () => set({ isOpen: true }),
        setPosition: (position) => set({ position }),
        toggleOption: (label: string) => {
          set((state) => ({
            toggleStates: { ...state.toggleStates, [label]: !state.toggleStates[label] },
          }))
        },
      },
    }),
    {
      name: 'admin-context-menu',
      partialize: (state) => ({ toggleStates: state.toggleStates }),
    }
  )
)

export const useIsAdminContextMenuOpen = () => useAdminContextMenuStore((state) => state.isOpen)
export const useAdminContextMenuOptions = () => useAdminContextMenuStore((state) => state.options)
export const useAdminContextMenuToggleStates = () =>
  useAdminContextMenuStore((state) => state.toggleStates)
export const useAdminContextMenuPosition = () => useAdminContextMenuStore((state) => state.position)
export const useAdminContextMenuActions = () => useAdminContextMenuStore((state) => state.actions)

export function useReadAdminContextMenuToggle(toggleLabel: string): boolean {
  const toggleStates = useAdminContextMenuToggleStates()

  // because the toggle is ready from local storage, the toggle state is not
  // available on the server and will always return false on first render.
  const isClient = useClientOnly()

  return isClient && toggleStates?.[toggleLabel]
}

export function useSetAdminContextMenuActions(heading: string, options: MenuOption[]) {
  useEffect(() => {
    useAdminContextMenuStore.setState((state) => ({
      options: { ...state.options, [heading]: options },
    }))

    return () => {
      useAdminContextMenuStore.setState((state) => {
        const { [heading]: _, ...options } = state.options
        return { options }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heading])
}
